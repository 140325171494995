.filledButton {
  border-radius: 2px;
  padding: 24px 36px;
  background: #1200ff;
  font-size: 36px;
}

.filledSecondaryButton {
  border-radius: 2px;
  background: #ffffff;
  color: #1200ff;
  padding: 16px 32px;
  font-size: 26px;
  transition: all 100ms ease-in;
  text-decoration: none;
  font-weight: 600;
}

.filledSecondaryButton:hover {
  background: #e7e7e7;
  color: #1200ff;
  transition: all 100ms ease-out;
}

.container {
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  height: 100vh;
  width: 100%;
}

.content {
  display: flex;
  padding: 16px;
  overflow-y: auto;
  flex-direction: column;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
}

.header {
  flex: 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogo {
  height: 60px;
  flex: 0;
  width: 100%;
}

.headerLogo img {
  width: auto;
  height: 60px;
}

.headerCta {
  display: none;
  flex: 1;
  justify-content: flex-end;
}

.masthead {
  flex: 1 1 auto;
  background-color: #d1cdff;
  background: #d1cdff url("./yepale_futuristic_girl_wilmer-martinez.jpg")
    no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mastheadTitle {
  color: #fff;
  font-size: 24px;
  padding: 0 16px;
  text-align: center;
  text-shadow: 2px 4px 15px black;
  line-height: 32px;
  letter-spacing: 0.8px;
  margin-bottom: 46px;
}

@media all and (min-width: 600px) {
  .mastheadTitle {
    max-width: 80%;
    font-size: 42px;
    line-height: 52px;
  }
}

@media all and (min-width: 1000px) {
  .content {
    padding: 36px;
    width: calc(100% - 72px);
    height: calc(100% - 72px);
  }
  .filledSecondaryButton {
    padding: 18px 36px;
    font-size: 36px;
  }
  .header {
    margin-bottom: 32px;
  }
  .headerCta {
    display: flex;
  }
  .headerLogo {
    height: 100px;
  }
  .headerLogo img {
    height: 100px;
  }
  .mastheadTitle {
    font-size: 64px;
    padding: 0;
    max-width: 70%;
    line-height: 72px;
    letter-spacing: 2px;
    margin: 0;
    margin-bottom: 54px;
  }
}
